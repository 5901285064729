
// Libraries
import { Component, Vue } from "nuxt-property-decorator";
import { PaymentTypes } from "~/operations/messages";

// Components
import TransformNavigationMenu from "~/components/organisms/nav/TransformNavigationMenu.vue";

type TransformMenuItems = {
  name: string;
  title: string;
  link: { path: string };
  externalLink: boolean;
};

@Component({
  scrollToTop: true,
  components: {
    TransformNavigationMenu,
  },
})
export default class InfosIndexPage extends Vue {
  PaymentTypes = PaymentTypes;

  transformMenuItems: Array<TransformMenuItems> = [
    {
      name: `${PaymentTypes.guaranteedPayment}`,
      title: `Erfahren Sie worauf Sie beim ${PaymentTypes.guaranteedPayment} von Heizöl beachten sollten`,
      link: { path: `/infos/ratenkauf` },
      externalLink: false,
    },
    {
      name: `FAQ`,
      title: `Häufig gestellte Fragen werden in unseren FAQs beantwortet`,
      link: { path: `/infos/faq` },
      externalLink: false,
    },
    {
      name: `Heizöl-Sorten`,
      title: `Erfahren Sie mehr über die verschiedenen Heizöl-Sorten`,
      link: { path: `/infos/heizoel-sorten` },
      externalLink: false,
    },
    {
      name: `CO2-Steuer`,
      title: `Erfahren Sie mehr über die CO2-Steuer`,
      link: { path: `/infos/co2-steuer` },
      externalLink: false,
    },
    {
      name: `Bewertungen`,
      title: `Echte Kunden-Erfahrungen bei esyoil. Prüfen Sie Ihren Heizöl-Händler! Meinungen, Bewertungen, Kommentare und Erfahrungsberichte über den Heizöl-Handel.`,
      link: { path: `/infos/bewertungen` },
      externalLink: false,
    },
    {
      name: `Qualitätsmanagement`,
      title: `Qualitätsmanagement nach ISO 9001`,
      link: { path: `/infos/qualitaetsmanagement` },
      externalLink: false,
    },
    {
      name: `HVO-Heizöl`,
      title: `HVO-Heizöl`,
      link: { path: `/infos/hvo-heizoel` },
      externalLink: false,
    },
  ];

  getActiveTabName(): string {
    return this.transformMenuItems.filter((ele) => {
      return this.$route.fullPath.includes(ele.link.path);
    })[0].name;
  }
}
